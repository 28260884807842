.main{
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
} 
.video{
  position: fixed;
  background-color: black;
  opacity: 0.2;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
.logo{
  margin-top: 10%;
  width: 100%;
  height: 80vh;
  z-index: 1;
}    